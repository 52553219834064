import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import s from "./Discounts.module.css";

const Discounts = ({ title, description, oldPrice, newPrice, image }) => {
    const form = useRef();
    const [isSent, setIsSent] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_1k0y0eh', 'template_m43doi2', form.current, 'c9duevq4BcBbs5E9W')
            .then(() => {
                setIsSent(true);
                if (window._tmr) {
                    window._tmr.push({ type: 'reachGoal', id: 3559899, goal: 'GOAL_NAME' });
                    console.log("Цель передана");
                } else {
                    console.error("Ошибка: объект _tmr не найден");
                }
            }, (error) => {
                console.error(error.text);
            });
    };

    return (
        <div className={s.container}>
            <div className={s.offerSection}>
                <h1 className={s.offerTitle}>{title}</h1>
                <p className={s.offerDescription}>
                    {description}
                    <br />
                    <span style={{ textDecoration: 'line-through', marginRight: '10px' }}>
                        {oldPrice} ₽
                    </span>
                    <br />
                    <span style={{ color: 'red', fontWeight: 'bold' }}>
                        {newPrice} ₽
                    </span>
                    <br />
                    Отправь номер телефона сейчас и получи выгоду
                </p>
                <img
                    className={s.offerImage}
                    src={image}
                    alt="Скидка"
                />
            </div>
            <div className={s.formSection}>
                <form className={s.form} ref={form} onSubmit={sendEmail}>
                <div className={s.inputGroup}>
                        
                        <input type="text" id="name" name="name" className={s.input} placeholder="Введите ваше имя" required />
                    </div>
                    <div className={s.inputGroup}>

                        <input type="tel" id="phone" name="phone" className={s.input} placeholder="Введите ваш номер телефона" required />
                    </div>
                    <button
                        type="submit"
                        className={`${s.submitButton} ${isSent ? s.sentButton : ''}`}
                        disabled={isSent}
                    >
                        {isSent ? 'Отправлено' : 'Отправить'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Discounts;
