import React from 'react';
import Discounts from "../Discounts/Discounts";
import Image1 from '../../image/sale1.jpg';

const Discount1 = () => {
    return (
        <Discounts
            title="Экстра-выгода!"
            description="Сет 1 (Подмышки, руки, ноги)"
            oldPrice={3228}
            newPrice={1936}
            image={Image1}
        />
    );
};

export default Discount1;
