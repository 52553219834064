import React from 'react';
import Discounts from "../Discounts/Discounts";
import Image1 from '../../image/Set7.png';

const Discount1 = () => {
    return (
        <Discounts
            title="Экстра-выгода!"
            description="Сет 3 (бикини, подмышки, голени + 1/2 бедра)"
            oldPrice={4428}
            newPrice={2656}
            image={Image1}
        />
    );
};

export default Discount1;
