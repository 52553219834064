import './App.css';
import React, {useEffect, useState} from "react";
import Header from "./components/Header/Header";
import Navbar from "./components/Navbar/Navbar";
import Content from "./components/Content/Content";
import Price from "./components/Price/Price";
import Discount1 from "./components/Discount/Discount1";
import Discount2 from "./components/Discount/Discount2";
import Discount3 from "./components/Discount/Discount3";
import Passes from "./components/Passes/Passes";
import Contacts from "./components/Contacts/Contacts";
import {Route, Routes} from "react-router-dom";
import Modal from "./components/Modal/Modal";
import Preloader from "./components/Preloader/Preloader";
import Footer from "./components/Footer/Footer";
import Laserepil from "./components/Laserepil/Laserepil";
import Endosfera from "./components/Endosfera/Endosfera";

import { FloatingWhatsApp } from 'react-floating-whatsapp';
import ava from './image/logoava.png';



const App = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        // Предположим, что загрузка данных займет не более 3 секунд
        const loadingTimeout = setTimeout(() => {
            setLoading(false);
        }, 0);//стояло 2200 для загрузки анимации ставлю 0 для dev
        // Очистите таймаут при размонтировании компонента
        return () => clearTimeout(loadingTimeout);
    }, []);
    const openModalWithDelay = () => {
        // Устанавливаем состояние модального окна в true через 500 миллисекунд (половина секунды)
        setTimeout(() => {
            setIsModalOpen(true);
        }, 0);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    // Показываем прелоадер, пока идет загрузка
    if (loading) {
        return (
            <div className='preloader'>
                <Preloader/>
            </div>
        );
    }

    return (
        <div>
            <div className='app-wrapper'>
                <Header/>
                <Modal isOpen={isModalOpen} onClose={closeModal}/>
                <Navbar openModal={openModalWithDelay}/>
                <Routes>
                    <Route path="/" element={<Content/>}/>
                    <Route path="/price" element={<Price/>}/>
                    <Route path="/sekret1" element={<Discount1/>}/>
                    <Route path="/sekret2" element={<Discount2/>}/>
                    <Route path="/sekret3" element={<Discount3/>}/>
                    <Route path="/passes" element={<Passes/>}/>
                    <Route path="/contacts" element={<Contacts/>}/>
                    <Route path="/laserepil" element={<Laserepil/>}/>
                    <Route path="/endosfera" element={<Endosfera/>}/>
                    
                </Routes>
                <Footer/>
                <FloatingWhatsApp
                    phoneNumber="+79082086314" // Ваш номер WhatsApp
                    accountName="Laser Queen Красноярск"
                    statusMessage="Обычно отвечает в течение 15 минут"
                    chatMessage="Здравствуйте! Чем могу помочь?"
                    avatar = {ava} // опционально, аватар оператора
                />
            </div>
        </div>
    );
}
export default App;
