import React from 'react';
import Discounts from "../Discounts/Discounts";
import Image1 from '../../image/Set8.png';

const Discount1 = () => {
    return (
        <Discounts
            title="Экстра-выгода!"
            description="Лазерная эпиляция всего тела"
            oldPrice={6828}
            newPrice={4096}
            image={Image1}
        />
    );
};

export default Discount1;
